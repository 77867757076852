var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var CardsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\ndisplay:flex;\ngap:3rem;\nflex-wrap:wrap;\nmargin-top:30px;\n\n"], ["\n\ndisplay:flex;\ngap:3rem;\nflex-wrap:wrap;\nmargin-top:30px;\n\n"])));
export var Card = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\nwidth: calc(50% - 25px);\n\n\n@media (max-width: 767px) {\n  width: 100%;\n"], ["\n\nwidth: calc(50% - 25px);\n\n\n@media (max-width: 767px) {\n  width: 100%;\n"])));
export var Company = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display:flex;\n\n  img{\n    height:15px;\n    width:15px;\n    margin-right:10px;\n    border:none;\n    align-self:center;\n  }\n  span{\n    color:", ";\n    font-size:1rem;\n  }\n\n"], ["\n  display:flex;\n\n  img{\n    height:15px;\n    width:15px;\n    margin-right:10px;\n    border:none;\n    align-self:center;\n  }\n  span{\n    color:", ";\n    font-size:1rem;\n  }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Address = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\ndisplay:flex;\n\nimg{\n    height:15px;\n    width:15px;\n    margin-right:10px;\n    border:none;\n    align-self:center;\n  }\n  span{\n    color:", ";\n    font-size:1rem;\n  }\n"], ["\ndisplay:flex;\n\nimg{\n    height:15px;\n    width:15px;\n    margin-right:10px;\n    border:none;\n    align-self:center;\n  }\n  span{\n    color:", ";\n    font-size:1rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.blueFonce;
});
export var Icon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nposition:absolute;\ntop:20px;\nright:20px;\nheight:47px;\nwidth:47px;\nimg{\n   height:47px;\n   width:47px;\n   background:", ";\n   padding:8px;\n   border:none;\n   border-radius:6px;\n}\n"], ["\nposition:absolute;\ntop:20px;\nright:20px;\nheight:47px;\nwidth:47px;\nimg{\n   height:47px;\n   width:47px;\n   background:", ";\n   padding:8px;\n   border:none;\n   border-radius:6px;\n}\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bluePiv;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
