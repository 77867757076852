var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Row } from "../../shared-components/Ui/Row";
import { motion } from "framer-motion";
import { Category, Title, Text, CompanyImageContainer, IconContainerBig, CompanyDetails, } from "../../Styles/SharedStyledComponents";
import MembersContext from "../../../../application/Context/MembersContext";
import { sliceStringAfterMaxLength } from "../../../../../src/utils/Utils";
export var List = function () {
    var _a, _b;
    var companies = useContext(MembersContext).companies;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var urlPrefix = langCode == "fr" ? "/fr" : "/en";
    var currentAuthStatus = ((_b = document.getElementById("auth-user")) === null || _b === void 0 ? void 0 : _b.value) || false;
    var isAthencticated = currentAuthStatus == "True" ? true : false;
    function getNomLegal(langCode, company) {
        if (langCode === "fr") {
            return company.nom_legal_fr || company.nom_legal_en || company.nom_legal;
        }
        else {
            return company.nom_legal_en || company.nom_legal_fr || company.nom_legal;
        }
    }
    function getNomUsuel(langCode, company) {
        if (langCode === "fr") {
            return company.nom_usuel_fr || company.nom_usuel_en || company.nom_usuel;
        }
        else {
            return company.nom_usuel_en || company.nom_usuel_fr || company.nom_usuel;
        }
    }
    return (_jsx(React.Fragment, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: companies.map(function (company, i) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
                return (_jsx(CompanyDetails, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: "".concat(urlPrefix).concat(langCode == "fr" ? company.absolute_url_f : company.absolute_url_en) }, { children: _jsxs(CompanyImageContainer, __assign({ marginBottom: 14 }, { children: [_jsx("img", { src: company.logo_url ? company.logo_url : process.env.IMG_URL + "/static/vendors/images/default-Image-company.png", alt: "banner" }), company.badge_url && (_jsx(IconContainerBig, { children: _jsx("img", { src: company.badge_url, alt: company.type_company ? company.type_company : "company badge" }) }))] })) })) })), _jsx("div", __assign({ className: "col-md-7 mb-3 mb-md-0" }, { children: _jsxs(Text, { children: [_jsx(Title, { children: _jsx("a", __assign({ href: "".concat(urlPrefix).concat(langCode === "fr" ? company.absolute_url_fr : company.absolute_url_en) }, { children: isAthencticated
                                                    ? getNomLegal(langCode, company)
                                                    : getNomUsuel(langCode, company) })) }), _jsx(Category, { children: _jsx("li", { children: ((_a = company.code_scian_primaire) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs(_Fragment, { children: [company.code_scian_primaire[0]['code'], " -", ' ', company.code_scian_primaire[0]["title_".concat(langCode)]] })) }) }), (((_c = (_b = company === null || company === void 0 ? void 0 : company.adresses) === null || _b === void 0 ? void 0 : _b.mrc) === null || _c === void 0 ? void 0 : _c.nom) !== "HORS-MRC" && ((_d = company === null || company === void 0 ? void 0 : company.adresses) === null || _d === void 0 ? void 0 : _d.region_adminsitrative)) ||
                                            ((_f = (_e = company === null || company === void 0 ? void 0 : company.adresses) === null || _e === void 0 ? void 0 : _e.province) === null || _f === void 0 ? void 0 : _f.nom) ? (_jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/apartment-blue-fonce.svg"), alt: "apartment" }), _jsx("span", { children: ((_h = (_g = company === null || company === void 0 ? void 0 : company.adresses) === null || _g === void 0 ? void 0 : _g.mrc) === null || _h === void 0 ? void 0 : _h.nom) !== "HORS-MRC" && ((_j = company === null || company === void 0 ? void 0 : company.adresses) === null || _j === void 0 ? void 0 : _j.region_adminsitrative) ?
                                                        "".concat(company.adresses.mrc.nom, ", ").concat(company.adresses.region_adminsitrative.nom)
                                                        : (((_l = (_k = company === null || company === void 0 ? void 0 : company.adresses) === null || _k === void 0 ? void 0 : _k.region_adminsitrative) === null || _l === void 0 ? void 0 : _l.nom) && ((_o = (_m = company === null || company === void 0 ? void 0 : company.adresses) === null || _m === void 0 ? void 0 : _m.province) === null || _o === void 0 ? void 0 : _o.nom) ?
                                                            "".concat(company.adresses.region_adminsitrative.nom, ", ").concat(company.adresses.province.nom)
                                                            : ((_q = (_p = company === null || company === void 0 ? void 0 : company.adresses) === null || _p === void 0 ? void 0 : _p.region_adminsitrative) === null || _q === void 0 ? void 0 : _q.nom) || ((_s = (_r = company === null || company === void 0 ? void 0 : company.adresses) === null || _r === void 0 ? void 0 : _r.province) === null || _s === void 0 ? void 0 : _s.nom) || "") })] })) : null, _jsx("p", { children: sliceStringAfterMaxLength(120, company.description_publique) })] }) }))] }) }, i));
            }) })) }));
};
