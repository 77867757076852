var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Title } from "../../shared-components/Title/Title";
import MembersContext from "../../../../application/Context/MembersContext";
import { useTranslation } from "react-i18next";
export var ViewToggler = function () {
    var _a;
    var t = useTranslation(["titles"]).t;
    var _b = useContext(MembersContext), view = _b.view, handleView = _b.handleView, getTitle = _b.getTitle;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var element = document.getElementById('members');
    var valeurDataFilter = element.getAttribute('data-filter');
    var title = getTitle(langCode, valeurDataFilter);
    var capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    var concatenatedTitle = t("Membres") + ' - ' + capitalizedTitle;
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "d-flex flex-wrap justify-content-between", style: { alignItems: 'baseline' } }, { children: [_jsx(Title, { title: concatenatedTitle }), _jsxs("div", __assign({ className: "d-flex gap-2" }, { children: [_jsx("div", __assign({ onClick: handleView, style: { width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/f-thumbs").concat(view == "carte" ? "-active" : "", ".svg"), alt: "carte icon" }) })), _jsx("div", __assign({ onClick: handleView, style: { width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/f-list").concat(view == "list" ? "-active" : "", ".svg"), alt: "list icon" }) }))] }))] })) }));
};
